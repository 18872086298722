<template>
  <component :is="page"></component>
</template>

<script>
import { mapGetters } from "vuex"
import RegionNewEventPage from "@/views/events/RegionNewEventPage"
import OrganizationNewEventPage from "@/views/events/OrganizationNewEventPage"

export default {
  metaInfo: {
    title: "Neue Veranstaltung"
  },
  components: {
    RegionNewEventPage,
    OrganizationNewEventPage
  },
  computed: {
    page() {
      if (this.isSuperAdmin || this.isRegionUser) {
        return "region-new-event-page"
      } else {
        return "organization-new-event-page"
      }
    },
    ...mapGetters("auth", [
      "isSuperAdmin",
      "isOrganizationUser",
      "isRegionUser"
    ])
  }
}
</script>
