<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Veranstaltung">
      <event-form
        :event="event"
        submit-label="Neue Veranstaltung anlegen"
        :form-submitted="saveEvent"
        mode="new"
      ></event-form>
    </page-content>
  </div>
</template>

<script>
import { addDays, addHours, setHours, setMinutes } from "date-fns"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventForm from "@/components/forms/EventForm"
import EventsRepository from "@/repositories/events_repository.js"

export default {
  components: {
    TheBreadcrumbs,
    PageContent,
    EventForm
  },
  data() {
    return {
      event: {
        has_location: true,
        dates_attributes: [
          {
            starts_at: this.createDefaultDates()[0],
            ends_at: this.createDefaultDates()[1]
          }
        ]
      }
    }
  },
  created() {
    if (this.$route.params.cloneFrom) {
      this.event = this.$route.params.cloneFrom
      this.event.dates_attributes = [
        {
          starts_at: this.createDefaultDates()[0],
          ends_at: this.createDefaultDates()[1]
        }
      ]
    }
  },
  methods: {
    async saveEvent(event) {
      await EventsRepository.create(event)
      this.$router.push({ name: "EventsPage" })
    },
    createDefaultDates() {
      let now = new Date()
      let start = addDays(now, 1)
      start = setHours(setMinutes(start, 0), 9)
      let end = addHours(start, 1)
      return [start, end]
    }
  }
}
</script>
